@import "../../base/fn";
@import "weui-tabbar";
@import "weui-navbar";

.weui-tab {
    position: relative;
    height: 100%;
}

.weui-tab__panel {
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 50px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.weui-tab__content{
    display: none;
}