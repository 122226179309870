@import "../../base/fn";

.weui-btn_loading{
	.weui-loading{
		margin:-.2em .34em 0 0;
	}
    &.weui-btn_primary, &.weui-btn_warn {
        color: @weuiBtnActiveFontColor;
    }
	&.weui-btn_primary{
        background-color: @weuiBtnPrimaryActiveBg;
	}
	&.weui-btn_warn{
        background-color: @weuiBtnWarnActiveBg;
	}
}
